import logo from './assets/images/jslogo.png';
import Cursor from './components/Cursor/Cursor.js'
import './App.css';
import './components/Cursor/Cursor.css'

const links = [
  { linkName: 'Linkedin', className: 'App-link', href: 'https://www.linkedin.com/in/sayurimizuguchi/', target: '_blank', rel: 'noopener noreferrer' },
  { linkName: 'Github', className: 'App-link', href: 'https://github.com/sayurimizuguchi', target: '_blank', rel: 'noopener noreferrer' },
  { linkName: 'StackOverflow', className: 'App-link', href: 'https://stackoverflow.com/users/7133482/sayuri-mizuguchi', target: '_blank', rel: 'noopener noreferrer' },
];

function App() {
  return (
    <div className="App">
      <Cursor />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hello, I'm <b>Sayuri Mizuguchi</b>. <br />
          I'm passionate about coding and always strive to improve my skills. <br />
          I am a <b>Principal Software Engineer</b> and <i>Mobile Quality Moderator</i> at Lazer Technologies. <br />
          I actively contribute to open-source projects for React libraries, and I'm ranked in the top 8% of engineers on StackOverflow, the largest, most trusted online community for developers.<br /> 
          You can check out more about it here:
        </p>
        {
          links.map(link => {
            return (
              <a
                className={link.className}
                href={link.href}
                target={link.target}
                rel={link.rel}
              >
                {link.linkName}
              </a>
            )
          })
        }
      </header>
    </div>
  );
}

export default App;
